<template >
<Layout>
    <div class="alertMsg">
        <b-alert v-model="showDismissibleAlert" :variant="alertvariant" dismissible>
          <h5>Success!</h5>
            {{alertMsg}}
          </b-alert>
    </div>

    
    <PageHeader :title="title" :items="items" />

      
<form @submit.prevent="submitCorporateForm" method="post">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                <div class="row">
                  <div class="col-md-6 row">
                    <div class="mb-3 col-md-4">
                     
                        <label class="form-label" for="formrow-corporateLogo-input">Corporate Logo</label>
                            <div class="file-upload-form">
                                Choose File
                                <input type="file" @change="previewImage" class="uploadInput"  accept="image/*">
                            </div>
                           
                  </div>
                   <div class="image-preview col-md-3" v-if="imageData.length > 0">
                                <img class="preview rounded-circle" :src="imageData">
                    </div>

                     
                      </div>

                  
                </div>
                <div class="row">
                     <div class="mb-3 col-md-6">
                        <label class="form-label" for="formrow-corporateName-input">Corporate Name *</label>
                        <input type="text" class="form-control" id="formrow-corporateName-input" name="corporateName" 
                        v-model="posts.corporateName"
                        :class="{
                            'is-invalid': submitted && $v.posts.corporateName.$error,
                          }" >
                        <div
                          v-if="submitted && $v.posts.corporateName.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.posts.corporateName.required"
                            >This value is required.</span
                          >
                        </div>
                    </div>
                    
                   

                </div>
               
                   
              <div class="row">
                    <div class="col-md-6 mb-3">
                        <label class="form-label" for="formrow-website-input">Website *</label>
                        <input type="text" class="form-control" id="formrow-website-input" v-model="posts.website"
                        :class="{
                            'is-invalid': submitted && $v.posts.website.$error,
                          }"
                        >
                         <div
                          v-if="submitted && $v.posts.website.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.posts.website.required"
                            >This value is required.</span
                          >
                        </div>
                
                    </div>
                    <div class="col-md-6 mb-4">
                        <label class="form-label" for="input-contactNO">Contact NO.</label>
                        <input type="number" id="input-contactNO" class="form-control"  min="0"
                         v-model="posts.contactNO" :class="{
                                'is-invalid': submitted && $v.posts.contactNO.$error,
                              }"
                        >
                        <div
                            v-if="submitted && $v.posts.contactNO.$error"
                            class="invalid-feedback"
                            >
                         <span v-if="!$v.posts.contactNO.maxLength"
                              >This value is too long. It should have 10 digits</span
                            >
                             <span v-if="!$v.posts.contactNO.minLength"
                              >This value length is invalid. It should be 
                              10 digits</span
                            >
                      </div>
                    </div>
              </div>

              <div class="col-md-6 mb-3">
                        <label class="form-label" for="formrow-website-input">Domain *</label>
                        <input type="text" class="form-control" id="formrow-website-input" v-model="posts.domain" placeholder="abcd.com"
                        :class="{
                            'is-invalid': submitted && $v.posts.domain.$error,
                          }"
                        >
                         <div
                          v-if="submitted && $v.posts.domain.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.posts.domain.required"
                            >This value is required.</span
                          >
                        </div>
                
                    </div>

                </div>
               
            </div>
        </div>


    </div>
<!--    <div class="col-lg-12">-->
<!--      <div class="card">-->
<!--        <div class="card-body">-->
<!--          <h6> Admin Details </h6>-->
<!--                      <div class="row">-->
<!--                    <div class="mb-3 col-md-6">-->
<!--                        <label class="form-label" for="formrow-adminName-input"> Admin Name *</label>-->
<!--                        <input type="text" class="form-control" id="formrow-adminName-input" :disabled="isEdit" v-model="posts.adminName"-->
<!--                          :class="{-->
<!--                                'is-invalid': submitted && $v.posts.adminName.$error,-->
<!--                              }"-->
<!--                        >-->
<!--                         <div-->
<!--                              v-if="submitted && $v.posts.adminName.$error"-->
<!--                              class="invalid-feedback"-->
<!--                            >-->
<!--                              <span v-if="!$v.posts.adminName.required"-->
<!--                                >This value is required.</span-->
<!--                              >-->
<!--                            </div>-->
<!--                       -->
<!--                  </div>-->

<!--                   <div class="mb-3 col-md-6">-->
<!--                        <label class="form-label" for="formrow-branchManPhoneNO-input"> Admin Mobile No</label>-->
<!--                        <input type="text" class="form-control" id="formrow-branchManPhoneNO-input" :disabled="isEdit"  v-model="posts.adminPhone"-->
<!--                          :class="{-->
<!--                                'is-invalid': submitted && $v.posts.adminPhone.$error,-->
<!--                              }"-->
<!--                        >-->
<!--                         <div-->
<!--                            v-if="submitted && $v.posts.adminPhone.$error"-->
<!--                            class="invalid-feedback"-->
<!--                            >-->
<!--                         <span v-if="!$v.posts.adminPhone.maxLength"-->
<!--                              >This value is too long. It should have 10 digits</span-->
<!--                            >-->
<!--                             <span v-if="!$v.posts.adminPhone.minLength"-->
<!--                              >This value length is invalid. It should be -->
<!--                              10 digits</span-->
<!--                            >-->
<!--                          </div>-->
<!--                      -->
<!--                       -->
<!--                  </div>-->
<!--              </div>-->
<!--               <div class="mb-3 col-md-6">-->
<!--                        <label class="form-label" for="formrow-branchManagerEmail-input">Admin EmailID *</label>-->
<!--                        <input type="text" class="form-control" id="formrow-branchManagerEmail-input" :disabled="isEdit" v-model="posts.adminEmail"-->
<!--                          :class="{-->
<!--                                'is-invalid': submitted && $v.posts.adminEmail.$error,-->
<!--                              }"-->
<!--                        >-->
<!--                         <div-->
<!--                              v-if="submitted && $v.posts.adminEmail.$error"-->
<!--                              class="invalid-feedback"-->
<!--                            >-->
<!--                              <span v-if="!$v.posts.adminEmail.required"-->
<!--                                >This value is required.</span-->
<!--                              >-->
<!--                            </div>-->
<!--                    -->
<!--                       -->
<!--                  </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                  <h6 class="mb-3"> Employee Registration Option </h6>
                  <div class="row">
                      <div class="col-md-4 mt-2">
                         
                             <b-form-radio
                                v-model="regWithEmailID"
                                class="mb-3"
                                value="1"
                                plain
                                >Register With Corporate Code</b-form-radio
                              >
                             
                          
                      </div>
                  </div>

                  <div class="row">
                      <div class="col-md-4 mt-2">
                         
                              <b-form-radio
                                v-model="regWithEmailID"
                                class="mb-3"
                                value="2"
                                plain
                                >Register with corporate code & validating email</b-form-radio
                              >
                             
                          
                      </div>
                      
                      <div class="col-md-4 mt-1">
                             <div class="input-group"><div class="input-group-text">@</div> 
                       
                              <input type="text"  class="form-control" id="formrow-domainEmailID-input"  
                              v-model="domainEmailID" placeholder="abcd.com">
                          </div>
                      </div>
                  </div>

                  <div class="row">
                      <div class="col-md-4 mt-2">
                      
                             <b-form-checkbox
                                v-model="regManually"
                                class="mb-3"
                                checked
                                plain
                                >Register employees manually</b-form-checkbox
                              >
                             
                          
                      </div>
                  </div>
                      <div class =" row" style="float:right;">
                           <b-spinner v-if="loading"
                            class="m-2 col-3"
                            variant="primary"
                            role="status"
                          ></b-spinner>
                          <button type="submit" v-if="$can('create corporate') && !isEdit" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
                          <button type="submit" v-if="$can('update corporate') && isEdit" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
                      </div>
                </div>
            </div>
    </div>
        <!-- <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                  
                 
                     <h6 class="mb-3">Wallet points validity  </h6>
                      <div class="col-md-6">
                        <b-form-radio
                            v-model="walletValidation"
                            class="custom-radio mb-3"
                            value="1"
                            plain
                            > Daily: Expires on same day at 11:59pm</b-form-radio
                          >
                      
                        </div>
                        <div class="col-md-6">
                         <b-form-radio
                            v-model="walletValidation"
                            class="mb-3"
                            value="2"
                            plain
                            >Weekly: Expires on every Sunday at 11:55pm</b-form-radio
                          >
                        </div>
                        <div class="col-md-6">
                         <b-form-radio
                            v-model="walletValidation"
                            class="mb-3"
                            value="3"
                            plain
                            >Monthly: Expires on every month end (Calendar month)</b-form-radio
                          >
                        </div>
                   
                   
                   </div>
                </div>
            </div> -->

</form>
<!-- Success circle button with ripple effect -->

  

</Layout>
</template>
<script>

// import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {
  required ,maxLength,minLength
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Corporate",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {

    // Multiselect,
    Layout,
    PageHeader,



  },
  data() {
    return {
      title: "Add Corporate",
      items: [
        {
          text: "Corporates",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],
      value: null,
      branchName: null,
      status :'',
      submitted: false,
      corporateID:0,
      isEdit:false,
      companyList:[],
      ownerList:[],
      maxlen: "",
      data: "",
      option: "",
      disabled: true,
      disabledOwner: false,
      imageData:'',
      ownerID:'',
      encodeImage:'',
      showDismissibleAlert :false,
      alertvariant:"success",
      alertMsg: "Corporate Registered Successfully!",
      loading: false,
      regManually:"",
      regWithEmailID:"1",
      regWithCorpCode:"",
      domainEmailID:"",
      walletValidation:"3",
      corpData:[],
      posts:{
        corporateName:null,
        corporateCode:null,
        foodType: [],
        value: '',
        corpAdminID :"",
        adminName:'',
        adminEmail:'',
        adminPhone:'',
        imageData:'',
        colorCode: '#000000',
        contactNO:"",
        website:"",
        cuisineType:[],
        password:"",
        companyList:[],
        ownerList:[],
        companyID:"",
        ownerID:'',
        domain:"",


      }
    };
  },

  validations: {
    posts: {
      corporateName: {
        required,
      },
      website:{
        required,
      },
      contactNO:{
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
      // adminPhone:{
      //   maxLength: maxLength(10),
      //   minLength: minLength(10),
      // },
      // adminName:{
      //   required,
      // },
      // adminEmail:{
      //   required,
      //   email
      // },
      domain:{
        required
      }

    },
  },
  methods:{

    submitCorporateForm(e){  //same function for add and edit
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if(this.regWithEmailID==2){
          if(this.domainEmailID==""){
            alert("Please Enter Domain Name");
            return;
          }
        }
        this.loading = true;
        let formData = new FormData();
        formData.append('corporateName', this.posts.corporateName);
        formData.append('corporateID',this.corporateID); // for edit form
        formData.append('website',this.posts.website);
        formData.append('contactNO',this.posts.contactNO);
        formData.append('domain',this.posts.domain);
        formData.append('ownerID',JSON.stringify(this.posts.ownerID));
        formData.append('document', this.encodeImage);
        formData.append('regManually', this.regManually);
        formData.append('regWithEmailID', this.regWithEmailID);
        formData.append('domainEmailID', this.domainEmailID);

        e.preventDefault();
        let apiName = "create-corporate";
        let routerPush = "corporateBranchForm";
        if(this.isEdit==true){
          apiName = "update-corporate";
          this.alertMsg = "Corporate Updated Successfully!"
          routerPush = "corporateTable";
        }
        this.axios.post(this.$loggedRole+"/"+apiName,  formData,
            {
              'Content-Type': 'multipart/form-data'
            })
            .then((result)=>{
              this.loading = false;
              this.$swal({
                text: result.data.message,
                icon: "success",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000
              });
              setTimeout(() => {
                this.$router.push({name: routerPush});
              },2000);

            })
            .catch((error)=> {
              this.loading = false;
              this.$swal({
                text: error.response.data.message,
                icon: "error",
              });
            });
      }
    },
    getAllBranchDetails(){
      this.axios.get(this.$loggedRole+"/getAllBranchDetails")
          .then((result)=>{
            this.branches = result.data.data;


          });
    },
    getCompanyList(){
      this.axios.get(this.$loggedRole+"/getCompanyList")
          .then((result)=>{
            this.companyList = result.data.companyArr;


          });
    },

    getCuisineList(){
      this.axios.get(this.$loggedRole+"/getCuisineList")
          .then((result)=>{
            this.cuisineList = result.data.data;

          });
    },
    changeColor(color) {
      const {rgba: {r, g, b, a}} = color;
      this.colorCode = `rgba(${r, g, b, a})`;
    },
    previewImage: function(event) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imageData = e.target.result;
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
        this.encodeImage = input.files[0];
      }
    },

    getCorporateDataByID(){
      this.loading=true;
      this.axios.get(this.$loggedRole+"/corporate/"+this.corporateID+"/edit",
          )
          .then((result)=>{
            this.loading=false;
            this.corpData = result.data.data;
            this.imageData = this.corpData.image;
            this.posts.corporateName = this.corpData.corporateName;
            this.posts.website = this.corpData.website;
            this.posts.contactNO = (this.corpData.contactNO) ? this.corpData.contactNO :"";
            this.posts.domain = this.corpData.domain;
            this.domainEmailID = this.corpData.domainEmailID;
            this.regManually = (this.corpData.registerManually==1)? true : false;
            this.regWithEmailID = this.corpData.registerWithEmail;
          });
    }


  },

  mounted(){
    if(this.$route.params.type=="Edit"){
      if(this.$route.params.id){
        sessionStorage.setItem('corporateID',this.$route.params.id);
      }

    }else{
      sessionStorage.setItem('corporateID',0);
    }
    this.corporateID = sessionStorage.getItem('corporateID');

    if(this.corporateID>0){
      this.getCorporateDataByID(this.corporateID);
      this.isEdit = true;

    }
    this.posts.companyID = this.companyList.find(option => option.companyID === 7);
    // this.getAllBranchDetails();
    // this.getCuisineList();
    // this.getCompanyList();


  },
  middleware: "authentication",
};
</script>
<style lang="scss" scoped>

img.preview {
    width: 100px;
    height: 100px;
    background-color: white;
    border: 1px solid #DDD;
    padding: 5px;
    object-fit: contain;
    margin-top: 6px;
}

.file-upload-form{
  padding: 5px 10px;
    background: #ececec;
    border: 1px solid #ececec;
    position: relative;
    color: black;
    border-radius: 2px;
    text-align: center;
    float: left;
    cursor: pointer;
}
.uploadInput{
  position: absolute;
    z-index: 1000;
    opacity: 0;
    cursor: pointer;
    right: 0;
    top: 0;
    height: 100%;
    font-size: 24px;
    width: 100%;
}

</style>


